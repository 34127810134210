<template>
    <OModal name="AddSourceOnPrerequisiteDlg" ref="AddSourceOnPrerequisiteDlg">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-titel">{{ $t('Sources in Prerequisites') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ODataGrid :dataObject="sources" hideGridMenu loadDataObject hideMultiselectColumn ref="sources_Grid">
                        <OColumn field="Source_ID" :headerName="$t('ID')" :headerTitle="$t('ID')" width="200" editable sortable #editor="{ row }">
                            <ODataLookup
                                :dataObject="sourcesLkp"
                                :bind="(sel: DataItemModel) => { row.Source_ID = sel.ID; }"
                                v-model="row.Source_ID"
                                :whereClause="sourceWhereClause"
                            >
                                <OColumn field="Source_ID" width="100" />
                                <OColumn field="Name" width="300" :headerName="$t('Name')" />
                                <OColumn field="OrgUnit" width="300" :headerName="$t('OrgUnit')" />
                            </ODataLookup>
                        </OColumn>
                        <OColumn field="Name" :headerName="$t('Name')" :headerTitle="$t('Name')" width="200" disabled sortable />
                        <OColumn field="OrgUnit" :headerName="$t('OrgUnit')" :headerTitle="$t('OrgUnit')" width="200" disabled sortable />
                    </ODataGrid>
                </div>
            </div>
        </div>
    </OModal>
</template>
<script setup lang="ts">
import { $t } from 'o365-utils';
import { ref } from 'vue';
import { context as myContext } from 'o365-modules';
import { type DataItemModel } from 'o365-dataobject';

const sources_Grid = ref<HTMLDivElement | null>(null);
const sourceWhereClause = ref<string>(`OrgUnitIdPath LIKE '${myContext.idPath}'`);

defineProps({
    sources: Object,
    sourcesLkp: Object
});


</script>